<template>
  <div class="p-4 agency-template">
    <h5 class="text-bold mb-3">Agency Website Details</h5>
    <div>
      <div class="grid-container mb-5">
        <div v-for="(template, idx) in templates" :key="idx">
          <label>
            <input
              type="radio"
              name="template"
              :value="template.id"
              v-model="form.template_id"
              :checked="template.id == form.template_id"
              class="d-none"
            />
            <div class="template card" :data-id="template.id">
              <div class="img-box">
                <div class="name px-2">
                  {{ template.title }}
                </div>
                <img :src="template.cover" alt="template" />
              </div>
            </div>
          </label>
        </div>
      </div>
      <div style="display: flex; gap: 20px;">
        <b-button type="submit" @click="goBack">Previous</b-button>
        <b-button type="submit" variant="primary" @click="saveAndContinue">Save & Continue</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { UPDATE_DFY_WEBSITE_MUTATION } from '~/graphql/mutations';
export default {
  name: 'AgencyTemplateForm',
  props: {
    templates: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    loading: false,
    form: {
      template_cover: '',
      template_id: '',
      id: null,
    },
  }),

  mounted() {
    this.getAgency();
    this.$watch('form.template_id', () => {
      this.form.template_cover = this.templates?.find(t => t.id == this.form.template_id)?.cover;
    });
  },

  methods: {
    async getAgency() {
      try {
        const url = `${process.env.VUE_APP_API_ROOT}/api/agencies/${this.$route.params.id}`;
        const data = await fetch(url);
        const response = await data.json();
        if (response?.agency) {
          this.updateForm(response?.agency);
        }
      } catch (error) {
        console.error(error);
      }
    },

    updateForm(form) {
      this.form.template_cover = form.template_cover;
      this.form.template_id = form.template_id;
      this.form.id = +this.$route.params.id;
    },

    async updateAgency() {
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_DFY_WEBSITE_MUTATION,
          variables: { ...this.form, template_id: '' + this.form.template_id },
        });
      } catch (error) {
        console.log(error);
      }
    },

    saveAndContinue() {
      this.updateAgency().then(() => {
        this.$router.push({
          query: {
            tab: 'agency-business-form',
          },
        });
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three equal-width columns */
  gap: 20px; /* Adjust the gap between columns as needed */
}
.agency-template {
  .img-box {
    width: 100%;
    aspect-ratio: 1/1;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  label {
    width: 100%;
    display: flex;
  }
  .template {
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    .name {
      background: white;
      text-align: center;
    }
  }

  input[type='radio']:checked ~ div.template {
    border: 2px solid $success;

    &::before {
      content: '\2713';
      box-sizing: border-box;
      background: $success;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 10px;
      border-radius: 50%;
      aspect-ratio: 1/1;
    }
  }
}
</style>
