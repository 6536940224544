<template>
  <div class="p-4 w-100">
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="text-bold mb-3">Agency Website Preview</h5>
      <div style="display: flex; gap: 20px;">
        <b-button type="submit" @click="goBack"> Previous</b-button>
        <b-button variant="primary" target="_blank" :href="`https://${data.subdomain}`"> Preview </b-button>
      </div>
    </div>

    <div class="row mb-5 align-items-center" v-if="data.template_cover">
      <div class="col-sm-4">
        <div>
          <div class="img-box" style="box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, .2); border-radius: 10px;">
            <img :src="data.template_cover" alt="" />
          </div>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="table">
          <table class="my-3">
            <tr>
              <td class="text-bold">Name</td>
              <td>{{ data.agency_name || 'No Name Added' }}</td>
            </tr>
            <tr>
              <td class="text-bold">Domain</td>
              <td>{{ data.domain }}</td>
            </tr>
            <tr>
              <td class="text-bold">Sub-domain</td>
              <td>{{ data.subdomain }}</td>
            </tr>
          </table>

          <div style="font-weight: 700 !important;">
            <b-btn-group class="w-100">
              <b-button class="w-50" @click="showCustomDomainAdder = !showCustomDomainAdder" variant="info">
                Add Custom Domain
              </b-button>
              <b-button class="w-50" :to="{ name: 'agency.websites.editor', query: { id: data.id } }" variant="primary">
                Use Website Editor
              </b-button>
            </b-btn-group>
          </div>
        </div>
      </div>
    </div>

    <transition name="fade" mode="out-in">
      <div class="accordion" v-show="showCustomDomainAdder" role="tablist">
        <h5 class="text-bold mb-3">Add your Agency Domain</h5>
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-3" role="tab">
            <b-button class="p-3 text-left" block variant="info" v-b-toggle.accordion-1
              ><b :class="['bg-primary p-1 px-2 rounded shadow-sm']">Step 1</b>
              <b> Retrieving Your Sub-domain</b></b-button
            >
          </b-card-header>
          <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
            <b-card-body class="fw-light">
              <b-card-text
                >Every agency receives a unique custom subdomain, enabling you to easily share your agency's link. This
                personalized subdomain plays a crucial role in helping your custom domain distinguish your agency within
                your network of agencies. To proceed, simply copy the assigned subdomain.
              </b-card-text>
              <b-form-group id="support-email " label="Copy the subdomain below." label-for="input-2">
                <b-form-input
                  id="input-2"
                  :value="stripProtocol(data.subdomain)"
                  disabled
                  placeholder="Enter admin email"
                ></b-form-input>
              </b-form-group>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-3" role="tab">
            <b-button block v-b-toggle.accordion-2 variant="info" class="p-3 text-left"
              ><b :class="['bg-primary text-black p-1 px-2 rounded shadow-sm']">Step 2</b>
              <b> Create a CNAME Record</b></b-button
            >
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body class="fw-light">
              <b-card-text
                >Now that you have your subdomain, it's time to create a <b>CNAME</b> record with your domain provider.
                Follow these steps:</b-card-text
              >
              <ol class="mt-1 ml-4">
                <li>Log in to your domain provider account</li>
                <li>Locate the <b>DNS</b> settings or DNS management section</li>
                <li>Look for an option to add a new record, and select CNAME</li>
                <li>
                  In the "Name" or "Host" field, enter your desired domain.
                </li>
                <li>
                  In the "Value" or "Points to" field, enter the subdomain
                  <b>{{ stripProtocol(data.subdomain) }}</b> provided
                </li>
              </ol>
              <p class="mb-3">After that, Click the button below.</p>
              <b-btn @click="showLastStep"
                ><b-spinner v-if="lastCDStep === 'loading'" small></b-spinner> <span class="sr-only">Loading...</span>I
                have done the above</b-btn
              >
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card no-body v-show="lastCDStep === 'show'" class="mb-1">
          <b-card-header header-tag="header" class="p-3" role="tab">
            <b-button block v-b-toggle.accordion-3 class="p-3 text-left" variant="info">
              <b :class="['bg-primary text-black p-1 px-2 rounded shadow-sm']">Step 3</b>
              <b> Verify and Save</b>
            </b-button>
          </b-card-header>
          <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
            <b-card-body class="fw-light">
              <p class="mb-3">
                After creating the CNAME record, it's time to verify and save your settings:
              </p>
              <p>Enter the custom domain you created on your domain provider below.</p>
              <b-form-group id="support-email " label="Custom Domain:" label-for="input-2">
                <b-form-input
                  id="input-2"
                  v-model="form.custom_domain"
                  placeholder="Enter your custom domain"
                ></b-form-input>
              </b-form-group>

              <b-btn variant="primary" @click="upload">
                Save <b-spinner v-if="loading" small type="grow"></b-spinner>
              </b-btn>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </transition>
  </div>
</template>

<script>
import { UPDATE_DFY_WEBSITE_MUTATION } from '~/graphql/mutations';

export default {
  name: 'AgencyPreview',
  props: {
    templates: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    selectedTemplate() {
      return this.templates.find(e => e.id === this.id);
    },
  },
  data: () => ({
    id: 2,
    loading: false,
    lastCDStep: 'hidden',
    showCustomDomainAdder: false,
    form: {},
    data: {
      subdomain: 'https://sub.linked.com',
      domain: 'No domain yet',
      agency_name: 'Red Hood',
      template_cover: null,
      id: null,
    },
  }),
  mounted() {
    this.getAgency();
  },
  methods: {
    async getAgency() {
      try {
        const url = `${process.env.VUE_APP_API_ROOT}/api/agencies/${this.$route.params.id}`;
        const data = await fetch(url);
        const response = await data.json();
        if (response?.agency) {
          this.updateForm(response?.agency);
        }
      } catch (error) {
        console.error(error);
      }
    },
    updateForm(form) {
      this.data.template_cover = form.template_cover;
      this.data.subdomain = form.sub_domain;
      this.data.agency_name = form.name;
      this.data.id = form.id;

      this.form.id = +this.$route.params.id;
      this.form.custom_domain = form.custom_domain;
      this.form.custom_domain = form.customDomain;
      this.form.support_email = form.support_email;
    },
    async updateAgency() {
      try {
        this.loading = true;
        await this.$apollo.mutate({
          mutation: UPDATE_DFY_WEBSITE_MUTATION,
          variables: { ...this.form, id: +this.$route.params.id },
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    stripProtocol(url) {
      const protocolSeparator = '://';
      const protocolIndex = url.indexOf(protocolSeparator);

      if (protocolIndex !== -1) {
        return url.substring(protocolIndex + protocolSeparator.length);
      }

      return url;
    },
    upload() {
      this.updateAgency();
    },
    showLastStep() {
      this.lastCDStep = 'loading';
      setTimeout(() => {
        this.lastCDStep = 'show';
      }, 10000);
    },
    goBack() {
      this.$router.go(-1); // Navigate to the previous step
    },
  },
};
</script>

<style lang="scss" scoped>
.img-box {
  overflow: clip;
  width: 100%;
  img {
    width: 100%;
    height: 200px;
  }
}

.table {
  display: flex;
  height: 100%;
  padding-top: 20px;
  padding-right: 20px;
  flex-direction: column;
  justify-content: space-between;
  table * {
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
  }
  td {
    padding: 5px 15px;
    &:last-child {
      font-weight: 300;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
