<template>
  <div class="workspace_header fixed-top py-1" :class="{ colored_header: isSticky }">
    <b-row style="background: #f2f2f2" class="align-items-center m-0 p-0 py-2 ">
      <b-col col md="3">
        <div class="header__data-welcome d-flex align-items-center text-left pl-4">
          <router-link :to="{ name: 'workspaces' }" class="item-link text-dark">
            <div class="logo-box">
              <img src="@/assets/images/logo/logo-block-black-n.png" />
            </div>
          </router-link>
          <!--<h6 class="font-weight-bold mb-0 pb-0">
            <router-link :to="{ name: 'workspaces' }" class="item-link text-dark">
              Welcome
              <span>{{ user.firstName }}</span>
              <svg-icon name="wink" size="sm" class="ml-2" />
            </router-link>
          </h6>-->
        </div>
      </b-col>
      <b-col col class="">
        <div class="header__data-info">
          <b-row class="justify-content-between align-items-center">
            <b-col v-if="isHideMenuItems" col md="">
              <b-button-group style="gap: 2rem;" class=" d-flex justify-content-center align-items-center w-100">
                <!-- <b-button
                  class="btn-sm btn-left px-3 py-2 relative text-white"
                  style="background-color: #634cd4;"
                  variant="success"
                >
                  <a href="https://app.useagencyai.com/" target="_blank" class="item-link text-white" title="AI Tools">
                    AI Tools
                  </a>
                  <div class="barge">Premium Bonus</div>
                </b-button> -->

                <div class=" " variant="success">
                  <router-link class="item-link item-links  " title="Mentorship" :to="{ name: 'bonus.index' }" exact>
                    <svg-icon name="mentorship-icon" size="md" class="ml-2" fill="none" />
                    <span>
                      Mentorship
                    </span>
                  </router-link>
                </div>
                <div class="  " variant="danger">
                  <router-link
                    class="item-links item-link   "
                    title="Client Finder"
                    :to="{ name: 'client.finder' }"
                    exact
                  >
                    <svg-icon name="client-finder" size="md" class="ml-2" fill="none" />
                    <span>
                      Client Finder
                    </span>
                  </router-link>
                </div>

                <!--<a
                    href="https://doc.clickup.com/4555986/p/h/4b16j-26060/db8a468b152f6c1"
                    target="_blank"
                    class="item-link text-white"
                    title="DFY Assets"
                  >
                    Assets
                  </a> -->
                <div class="   " variant="success">
                  <router-link
                    class="item-links item-link  "
                    title="Social Media Agency"
                    :to="{ name: 'dfy.assets' }"
                    exact
                  >
                    <svg-icon name="agency" size="md" class="ml-2" fill="none" />
                    <span>
                      Social Media Agency
                    </span>
                  </router-link>
                </div>
              </b-button-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col col md="3">
        <div class="d-flex align-items-center gap-2">
          <div class="user__data-notification d-flex px-2">
            <NotificationsMenu />
          </div>

          <div class="user__data d-flex align-items-center gap-2">
            <div @click="showAnnouncement = !showAnnouncement" class=" announcement-menu-info btn soft-outline">
              <svg-icon name="mic-icon" class="mr-1 text-primary cursor-pointer" size="md" />
              <p class="mb-0">Roadmap</p>
            </div>
            <div class="user">
              <UserMenu :user="user" :authBusy="authBusy" />
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <transition name="fade">
      <AnnouncementMenu v-if="showAnnouncement" @close="handleCloseAnnouncementMenu" />
    </transition>
  </div>
</template>

<script>
// import Stats from './Stats.vue';
import UserMenu from '../General/Menus/UserMenu.vue';
import { mapState } from 'vuex';
import NotificationsMenu from '../General/Menus/NotificationMenu.vue';
import AnnouncementMenu from '../General/Menus/AnnouncementMenu.vue';

export default {
  components: { NotificationsMenu, UserMenu, AnnouncementMenu }, //Stats
  data() {
    return {
      isSticky: false,
      scrollPosition: 0,
      showAnnouncement: false,
      isHideMenuItems: false,
    };
  },
  watch: {
    // Watch for route changes to update the menu
    $route(to, from) {
      this.updateMenuVisibility(to);
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.updateMenuVisibility(this.$route);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  computed: {
    ...mapState('auth', {
      user: state => state.user,
      authBusy: state => state.busy,
    }),

    isDashboard() {
      return (
        this.$route.name === 'workspaces' ||
        this.$route.name === 'bonus.index' ||
        this.$route.name === 'niches.index' ||
        this.$route.name === 'client.finder'
      );
    },
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
      if (this.scrollPosition >= 80) {
        this.isSticky = true;
      } else {
        this.isSticky = false;
      }
    },
    showGroupPage() {},

    handleCloseAnnouncementMenu() {
      this.showAnnouncement = false;
    },
    // method to hide menu items on video-demo page
    updateMenuVisibility(route) {
      if (route.name === 'demo.video') {
        this.isHideMenuItems = false;
      } else {
        this.isHideMenuItems = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.logo-box {
  height: 50px;
  & img {
    width: auto;
    height: 100%;
  }
}
.announcement-menu {
  z-index: 9999;
  display: inline-block;
  position: relative;

  &-info {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 10px;
    background: rgba(0, 0, 0, 0.05);
  }
}
.workspace_header {
  background: transparent;
  color: #000000;
  height: $navbar-height;
  &.colored_header {
    background-color: $light;
  }
  .stat {
    background: $gray;
    border-radius: 0 0 30px 30px;
    padding: 4px;
    height: $navbar-height;
    // margin-top: -8px;
  }
  .btn-left,
  .btn-right {
    font-size: 12px;
    font-weight: bold;
  }
  .btn-right {
    border-radius: 0 10px 10px 0 !important;
  }
  .btn-left {
    border-radius: 10px 0 0 10px !important;
  }
  .user__data {
    &-notification {
      // border-right: 2px solid #000000;
      // margin: 0 10px;
      // padding: 0 25px 0 0;
      line-height: 50px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.item-link {
  color: #1b4332;

  &:hover {
    text-decoration: none;
    color: #d49f4e;
  }
}
.item-links svg {
  fill: none !important;
}
.item-links span {
  font-size: 17px !important;
}

.relative {
  position: relative;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

/* Set the starting opacity to 0 for entering */
.fade-enter {
  opacity: 0;
}

/* Set the opacity to 0 when leaving */
.fade-leave-to {
  opacity: 0;
}

.router-link-active {
  font-size: 20px;
  font-weight: bold;
  color: #d49f4e;
}
</style>
